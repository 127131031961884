export default [
	{
		title: "Dashboard",
		route: "dashboard",
		icon: "",
	},
	{
		title: "Administradores",
		route: "administradores",
		icon: "",
	},
	{
		title: "Projetos",
		route: "projetos",
		icon: "",
	},
	{
		title: "Proponentes",
		route: "proponentes",
		icon: "",
	},
	{
		title: "Promotores",
		route: "promotores",
		icon: "",
	},
	{
		title: "Avaliadores",
		route: "avaliadores",
		icon: "",
	},
	{
		title: "Assessores",
		route: "assessores",
		icon: "",
	},
];
