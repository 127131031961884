<template>
	<div class="navbar-container d-flex content align-items-center">
		<!-- Nav Menu Toggler -->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link class="nav-link" @click="toggleVerticalMenuActive">
					<feather-icon icon="MenuIcon" size="21" />
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
			<!-- <dark-Toggler class="d-none d-lg-block" /> -->
			<h4 class="pl-1 font-weight-bold" style="padding-top: 8px">
				{{ $route.meta.pageTitle }}
			</h4>
		</div>

		<b-navbar-nav class="nav align-items-center ml-auto">
			<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
				<template #button-content>
					<div class="d-sm-flex d-none user-nav">
						<p class="user-name font-weight-bolder mb-0">{{ userData.name }}</p>
						<span class="user-status">Admin</span>
					</div>
					<b-avatar size="40" variant="secondary" :src="userData.profilePictureUrl" badge-variant="success" />
					<feather-icon icon="ChevronDownIcon" size="16" class="dropdown-icon" />
				</template>

				<b-dropdown-item @click="goToProfile()" link-class="d-flex align-items-center">
					<feather-icon size="16" icon="UserIcon" class="mr-50" />
					<span>Perfil</span>
				</b-dropdown-item>

				<b-dropdown-divider />

				<b-dropdown-item @click="logout" link-class="d-flex align-items-center">
					<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
					<span>Sair</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
	import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";

	// import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

	export default {
		components: {
			BLink,
			BNavbarNav,
			BNavItemDropdown,
			BDropdownItem,
			BDropdownDivider,
			BAvatar,

			// Navbar Components
			// DarkToggler,
		},
		props: {
			toggleVerticalMenuActive: {
				type: Function,
				default: () => {},
			},
		},
		methods: {
			async logout() {
				this.$store.dispatch("app/showLoading");
				await this.$store.dispatch("auth/logout");
				this.$store.dispatch("app/hideLoading");

				// Redirect to login page
				this.$router.push({ name: "login" });
			},
			goToProfile() {
				this.$router.push({ name: "administradores", params: { id: this.userData.id } });
			},
		},
		data() {
			return {
				userData: {},
			};
		},
		async mounted() {
			this.userData = await this.$store.dispatch("auth/getUserData");
		},
	};
</script>

<style lang="scss">
	@import "@/assets/scss/variables/_variables.scss";

	.dropdown-icon {
		margin-left: 5px;
		color: $primary;
	}
</style>
