// eslint-disable-next-line no-unused-vars
// import Vue from "vue";
import Vue from "../main";
import ToastificationContent from "../@core/components/toastification/ToastificationContent.vue";

// export async function loadingWrap(vueContext: Vue, functionToExecute: () => Promise<any>, successMessage = "", errorMessage = "Ocorreu um erro. Tente novamente.") {
// 	await vueContext.$vs.loading();
// 	try {
// 		await functionToExecute();
// 		if (successMessage) {
// 			vueContext.$vs.notify({ color: "success", title: "Sucesso", text: successMessage });
// 		}
// 	} catch (error) {
// 		console.error(error);
// 		vueContext.$vs.notify({ color: "danger", title: "Erro", text: errorMessage });
// 	}
// 	await vueContext.$vs.loading.close();
// }

export async function requireConfirmation(
	title = "Deseja prosseguir?",
	text = "Não será possível reverter essa operação",
	acceptText = "Continuar",
) {
	const confirmationResult = await Vue.$bvModal.msgBoxConfirm(text, {
		title,
		size: "sm",
		okVariant: "danger",
		okTitle: acceptText,
		cancelTitle: "Cancelar",
		cancelVariant: "outline-secondary",
		hideHeaderClose: true,
		centered: true,
	});

	if (!confirmationResult) {
		throw new Error("Operation cancelled");
	}
}

export enum AlertKind {
	SUCCESS = "SUCCESS",
	ALERT = "ALERT",
	ERROR = "ERROR",
}

const AlertKindConfig = (title?: string) => ({
	[AlertKind.SUCCESS]: { title: title ?? "Sucesso", variant: "success", icon: "CheckCircleIcon" },
	[AlertKind.ALERT]: { title: title ?? "Alerta", variant: "warning", icon: "AlertCircleIcon" },
	[AlertKind.ERROR]: { title: title ?? "Erro", variant: "danger", icon: "XCircleIcon" },
});

export function showAlert(kind: AlertKind, text: string, title?: string) {
	return Vue.$toast({
		component: ToastificationContent,
		props: {
			text,
			...AlertKindConfig(title)[kind],
		},
	});
}

export function removeDiacritics(value: string) {
	return value
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}
